import React from "react"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { injectIntl, IntlShape } from "react-intl"
import { API, graphqlOperation } from "aws-amplify"
import { faUpload } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import S3 from "aws-sdk/clients/s3"
import {
  Box,
  Button,
  Header,
  SpaceBetween,
  Alert,
  TableProps,
  Modal
} from "@amzn/awsui-components-react/polaris"
import VisibilitySensor from "react-visibility-sensor"
import axios from "axios"
import {
  generateDownloadS3PresignedUrlQuery,
  getEngagementAndCredentials as getEngagementAndCredentialsQuery,
  listLatestImportJobsByFileName as listLatestImportJobsByFileNameQuery
} from "./graphql/queries"
import common from "../Common.messages"
import messages from "./CustomerReportedFilesList.messages"
import CustomerReportedFilesUploadSingleFile from "./CustomerReportedFilesUploadSingleFile"
import AppConfig from "../../Config"
import UserPermissionLevel from "../../lib/auth/UserPermissionLevel"
import { getAbsoluteTimestamp } from "../utility/HelperFunctions"
import TableWrapper from "../utility/TableWrapper"

const { Config } = AppConfig

export enum ImportResultFileType {
  ValidationReport = "ValidationReport",
  ProcessedOriginal = "ProcessedOriginal"
}

export enum ErrorType {
  Warning = "warning",
  Info = "info"
}

interface IEngagementQueryResponse {
  getEngagement: {
    Id?: string
    Name?: string
    S3Bucket?: string
    S3KeyPrefix?: string
  }
}

interface ITempCredentialsQueryResponse {
  getCustomerDataTempCredentials: {
    AccessKey?: string
    SecretAccessKey?: string
    SessionToken?: string
    Expiration?: string
  }
}

interface IDownloadPresignedUrlQueryResponse {
  generateDownloadS3PresignedUrl: {
    PresignedUrl: string
  }
}

interface IImportJob {
  ValidationResult: {
    ValidationStatus: string | null
    ValidationReportS3PresignedUrl: string | null
    ValidationReportAvailable: boolean
  }
  ImportStatus: string | null
  Id: string
  DataFormat: string
  CreatedOn: string
  ImportSourceFile: {
    FileName: string
    ProcessedOriginalS3PresignedUrl: string | null
    ProcessedOriginalAvailable: boolean
  }
}

interface ICustomerReportedFile {
  Name: string
  LastModified: string
  FileFormat: string
  ImportId: string | null
  FileValidation: string | null
  ImportStatus: string | null
  ValidationReportAvailable: boolean
  ProcessedOriginalAvailable: boolean
}

interface ICustomerReportedFilesListState {
  bucketPath: string
  engagement: IEngagementQueryResponse
  credentials: ITempCredentialsQueryResponse
  loading: boolean
  customerReportedFiles: ICustomerReportedFile[]
  errorMessage: string | undefined
  errorType: ErrorType
  errorExist: boolean
  uploadVisible: boolean
  selectedFile?: ICustomerReportedFile
  lastUploadDate: Date | undefined
  downloadingReport: boolean
  downloadingProcessedOriginal: boolean
}

interface ICustomerReportedFilesListProps {
  intl: IntlShape
  engagementId: string
}

function getIsAutoImporterUser() {
  const permissionLevel = UserPermissionLevel.getInstance()
  return permissionLevel.getIsAdminUser() || permissionLevel.getIsTsoLogicUser()
}

export class CustomerReportedFilesList extends React.Component<
  RouteComponentProps & ICustomerReportedFilesListProps,
  ICustomerReportedFilesListState
> {
  private readonly S3_OBJECT_NAME: string = "Name"

  private readonly S3_OBJECT_LAST_MODIFIED: string = "LastModified"

  private readonly FILE_FORMAT: string = "FileFormat"

  private readonly IMPORT_STATUS: string = "ImportStatus"

  private readonly FILE_VALIDATION: string = "FileValidation"

  private isUnmounted: boolean = false

  private s3: S3 | null

  private formatMsg: Function

  private isUploadInProgress: boolean = false

  constructor(props: RouteComponentProps & ICustomerReportedFilesListProps) {
    super(props)

    const {
      intl: { formatMessage }
    } = this.props

    this.formatMsg = formatMessage
    this.state = {
      engagement: { getEngagement: {} },
      credentials: { getCustomerDataTempCredentials: {} },
      bucketPath: Config.CustomerReportedFiles.UploadDirectoryPath,
      loading: true,
      customerReportedFiles: [],
      errorExist: false,
      errorType: ErrorType.Warning,
      uploadVisible: false,
      errorMessage: undefined,
      selectedFile: undefined,
      lastUploadDate: undefined,
      downloadingReport: false,
      downloadingProcessedOriginal: false
    }

    this.s3 = null
  }

  async componentDidMount() {
    const { engagementId } = this.props

    this.isUnmounted = false

    if (engagementId) {
      await this.getEngagementAndUploadCredentials()
      await this.listFiles()
    }
  }

  async componentDidUpdate(
    prevProps: RouteComponentProps & ICustomerReportedFilesListProps
  ) {
    const { engagementId } = this.props
    const { engagementId: prevEngagementId } = prevProps

    if (prevEngagementId !== engagementId) {
      if (engagementId) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          loading: true,
          customerReportedFiles: [],
          engagement: { getEngagement: {} },
          credentials: { getCustomerDataTempCredentials: {} },
          errorExist: false,
          selectedFile: undefined,
          downloadingReport: false,
          downloadingProcessedOriginal: false
        })
        await this.getEngagementAndUploadCredentials()
        await this.listFiles()
      }
    }
  }

  componentWillUnmount() {
    this.isUnmounted = true
  }

  private setSelectedFile = (selectedFile: ICustomerReportedFile) => {
    this.setState({ selectedFile })
  }

  private onSelectedFileChange = (
    detail: TableProps.SelectionChangeDetail<ICustomerReportedFile>
  ) => {
    this.setSelectedFile(detail.selectedItems[0])
  }

  private getColumnDefinition(): TableProps.ColumnDefinition<
    ICustomerReportedFile
  >[] {
    const fileNameLinkColumn = {
      id: this.S3_OBJECT_NAME,
      cell: (item: ICustomerReportedFile) => (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Button
          variant="link"
          className="button-link"
          onClick={() => this.setSelectedFile(item)}
        >
          {item.Name}
        </Button>
      ),
      header: this.formatMsg(messages.headerFileName),
      minWidth: "160px",
      allowLineWrap: true,
      sortingField: this.S3_OBJECT_NAME
    }
    const fileNameColumn = {
      id: this.S3_OBJECT_NAME,
      cell: (item: ICustomerReportedFile) => item.Name,
      header: this.formatMsg(messages.headerFileName),
      minWidth: "160px",
      allowLineWrap: true,
      sortingField: this.S3_OBJECT_NAME
    }
    const fileFormatColumn = {
      id: this.FILE_FORMAT,
      cell: (item: ICustomerReportedFile) => item.FileFormat,
      header: this.formatMsg(messages.headerFileFormat),
      minWidth: "160px",
      allowLineWrap: true
    }
    const lastModifiedColumn = {
      id: this.S3_OBJECT_LAST_MODIFIED,
      cell: (item: ICustomerReportedFile) =>
        getAbsoluteTimestamp(item.LastModified),
      header: this.formatMsg(messages.headerLastModified),
      minWidth: "160px",
      allowLineWrap: true,
      sortingField: this.S3_OBJECT_LAST_MODIFIED
    }
    const fileValidationColumn = {
      id: this.FILE_VALIDATION,
      cell: (item: ICustomerReportedFile) => item.FileValidation,
      header: this.formatMsg(messages.headerFileValidation),
      minWidth: "160px",
      allowLineWrap: true
    }
    const importStatusColumn = {
      id: this.IMPORT_STATUS,
      cell: (item: ICustomerReportedFile) => item.ImportStatus,
      header: this.formatMsg(messages.headerImportStatus),
      minWidth: "160px",
      allowLineWrap: true
    }

    const columnDefinition = []

    if (getIsAutoImporterUser()) {
      columnDefinition.push(fileNameLinkColumn)
    } else {
      columnDefinition.push(fileNameColumn)
    }
    columnDefinition.push(fileFormatColumn)
    columnDefinition.push(lastModifiedColumn)
    if (getIsAutoImporterUser()) {
      columnDefinition.push(fileValidationColumn)
      columnDefinition.push(importStatusColumn)
    }

    return columnDefinition
  }

  private configureS3Client = () => {
    const {
      credentials: {
        getCustomerDataTempCredentials: {
          AccessKey,
          SecretAccessKey,
          SessionToken
        }
      }
    } = this.state

    this.s3 = new S3({
      apiVersion: "2006-03-01",
      accessKeyId: AccessKey,
      secretAccessKey: SecretAccessKey,
      sessionToken: SessionToken,
      httpOptions: { timeout: 0 }
    })
  }

  private getEngagementAndUploadCredentials = async () => {
    await this.getEngagementAndCredentials()
    this.configureS3Client()
  }

  private getEngagementAndCredentials = async () => {
    const { engagementId } = this.props

    if (!this.isUnmounted) {
      this.setState({ loading: true, errorExist: false })
    }

    try {
      const response = await API.graphql(
        graphqlOperation(getEngagementAndCredentialsQuery, {
          id: engagementId
        })
      )
      const queryResponse = response as {
        data: IEngagementQueryResponse & ITempCredentialsQueryResponse
      }
      this.updateState(queryResponse.data)
    } catch (err: any) { // eslint-disable-line
      if (!this.isUnmounted) {
        this.setState({
          loading: false,
          errorExist: true,
          errorType: ErrorType.Warning,
          errorMessage: `${this.formatMsg(
            messages.getEngagementErrorText
          )}: ${engagementId}`
        })
      }
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }

  private getImportJobs = async () => {
    const { engagementId } = this.props

    if (!this.isUnmounted) {
      this.setState({ loading: true })
    }

    if (engagementId) {
      try {
        const response = await API.graphql(
          graphqlOperation(listLatestImportJobsByFileNameQuery, {
            id: engagementId
          })
        )

        const importJobsResponse = response as {
          data: {
            listLatestImportJobsByFileName: {
              ImportJobs: IImportJob[]
            }
          }
        }
        return importJobsResponse.data
      } catch (err: any) { // eslint-disable-line
        if (!this.isUnmounted) {
          this.setState({
            loading: false,
            customerReportedFiles: [],
            errorExist: true,
            errorType: ErrorType.Warning,
            errorMessage: `${this.formatMsg(
              messages.getImportJobsErrorText
            )}: ${engagementId}`
          })
        }
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }

    return {
      listLatestImportJobsByFileName: {
        ImportJobs: []
      }
    }
  }

  private listFiles = async () => {
    const {
      engagement: {
        getEngagement: { S3Bucket: bucketName, S3KeyPrefix: keyPrefix }
      },
      credentials: {
        getCustomerDataTempCredentials: { Expiration }
      },
      bucketPath,
      selectedFile,
      errorExist
    } = this.state

    if (Expiration) {
      const now: Date = new Date()
      const expiration: Date = new Date(Expiration)
      if (expiration <= now) {
        await this.getEngagementAndUploadCredentials()
      }
    }

    if (!bucketName) return

    if (!this.isUnmounted) {
      this.setState({
        loading: true
      })
    }

    try {
      const bucketKeyPrefix = keyPrefix
        ? `${keyPrefix}${bucketPath}`
        : bucketPath
      const bucketParams = {
        Bucket: bucketName,
        Prefix: bucketKeyPrefix,
        StartAfter: bucketKeyPrefix,
        MaxKeys: 50
      }

      const listObjectsResponse = await this.s3!.listObjectsV2(
        bucketParams
      ).promise()

      if (listObjectsResponse.$response.error) {
        const errorMessage = listObjectsResponse.$response.error.message
        if (!this.isUnmounted) {
          this.setState({
            customerReportedFiles: [],
            loading: false,
            errorExist: true,
            errorType: ErrorType.Warning,
            errorMessage
          })
        }
        return
      }

      const customerReportedFileList: ICustomerReportedFile[] = []
      let latestUploadDate: Date | undefined
      let latestUploadFile: ICustomerReportedFile | undefined
      if (listObjectsResponse.$response.data) {
        const content = listObjectsResponse.$response.data
          .Contents as S3.ObjectList

        content.forEach(obj => {
          const lastModified = obj.LastModified as Date
          const s3ObjectKey = obj.Key as string
          const customerReportedFile = {
            Name: s3ObjectKey.replace(bucketParams.Prefix, "") as string,
            LastModified: lastModified.toISOString(),
            FileFormat: "",
            ImportId: "",
            FileValidation: "",
            ImportStatus: "",
            ProcessedOriginalAvailable: false,
            ValidationReportAvailable: false
          }
          customerReportedFileList.push(customerReportedFile)
          const uploadDate = new Date(lastModified)
          if (!latestUploadDate || latestUploadDate < uploadDate) {
            latestUploadDate = uploadDate
            latestUploadFile = customerReportedFile
          }
        })
      }

      const getImportJobsResponse = await this.getImportJobs()
      const { listLatestImportJobsByFileName } = getImportJobsResponse
      if (
        listLatestImportJobsByFileName &&
        listLatestImportJobsByFileName.ImportJobs
      ) {
        const importJobList = listLatestImportJobsByFileName.ImportJobs
        importJobList.forEach((obj: IImportJob) => {
          const lastModified = obj.CreatedOn as string
          const fileName = obj.ImportSourceFile.FileName as string
          const fileFormat = this.getFileFormatMessage(obj.DataFormat)
          const importId = obj.Id
          const fileValidation = this.getFileValidationMessage(
            obj.ValidationResult.ValidationStatus
          )
          const importStatus = this.getImportStatusMessage(obj.ImportStatus)
          const processedOriginalAvailable =
            obj.ImportSourceFile.ProcessedOriginalAvailable
          const validationReportAvailable =
            obj.ValidationResult.ValidationReportAvailable

          const existingFile = customerReportedFileList.find(
            e => e.Name === fileName
          )

          let customerReportedFile: ICustomerReportedFile
          if (existingFile) {
            customerReportedFile = existingFile
            existingFile.LastModified = lastModified
            existingFile.FileFormat = fileFormat
            existingFile.ImportId = importId
            existingFile.FileValidation = fileValidation
            existingFile.ImportStatus = importStatus
            existingFile.ProcessedOriginalAvailable = processedOriginalAvailable
            existingFile.ValidationReportAvailable = validationReportAvailable
          } else {
            customerReportedFile = {
              Name: fileName,
              LastModified: lastModified,
              FileFormat: fileFormat,
              ImportId: importId,
              FileValidation: fileValidation,
              ImportStatus: importStatus,
              ProcessedOriginalAvailable: processedOriginalAvailable,
              ValidationReportAvailable: validationReportAvailable
            }
            customerReportedFileList.push(customerReportedFile)
          }
          const uploadDate = new Date(lastModified)
          if (!latestUploadDate || latestUploadDate < uploadDate) {
            latestUploadDate = uploadDate
            latestUploadFile = customerReportedFile
          }
        })

        if (!this.isUnmounted) {
          let importErrorProperties = {}
          if (this.isFailedImport(latestUploadFile) && !errorExist) {
            importErrorProperties = {
              errorType: ErrorType.Info,
              errorExist: true,
              errorMessage: this.formatMsg(messages.errorInfoText)
            }
          }
          this.setState({
            lastUploadDate: latestUploadDate,
            ...importErrorProperties
          })
        }
      }

      //  add this for the success case
      if (!this.isUnmounted) {
        const updatedSelectedFile = customerReportedFileList.find(
          file => file.Name === selectedFile?.Name
        )
        this.setState({
          customerReportedFiles: customerReportedFileList,
          loading: false,
          selectedFile: updatedSelectedFile
        })
      }
    } catch (err: any) { // eslint-disable-line
      if (err.code && !this.isUnmounted) {
        this.setState({
          errorExist: true,
          errorType: ErrorType.Warning,
          errorMessage: `${err.code}: ${err.message}`
        })
      } else if (!this.isUnmounted) {
        this.setState({
          errorExist: true,
          errorType: ErrorType.Warning,
          errorMessage: err.Message
        })
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }

  private refreshFileList = async () => {
    const { engagementId } = this.props

    if (engagementId) {
      await this.listFiles()
    }
  }

  private onDownloadValidationReport = async () => {
    const { selectedFile } = this.state
    if (selectedFile && selectedFile.ValidationReportAvailable) {
      this.setState({ downloadingReport: true })

      await this.downloadFile(
        selectedFile,
        ImportResultFileType.ValidationReport
      )

      this.setState({ downloadingReport: false })
    }
  }

  private onDownloadProcessedOriginalFile = async () => {
    const { selectedFile } = this.state
    if (selectedFile && selectedFile.ProcessedOriginalAvailable) {
      this.setState({ downloadingProcessedOriginal: true })

      await this.downloadFile(
        selectedFile,
        ImportResultFileType.ProcessedOriginal
      )

      this.setState({ downloadingProcessedOriginal: false })
    }
  }

  private downloadFile = async (
    selectedFile: ICustomerReportedFile,
    fileType: ImportResultFileType
  ) => {
    try {
      const presignedUrl = await this.getPresignedUrl(selectedFile, fileType)
      // inspired by javilobo8, https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      axios.get(presignedUrl, { responseType: "blob" }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement("a")
        link.setAttribute("id", "downloadLink")
        document.body.appendChild(link)
        link.href = url

        if (fileType === ImportResultFileType.ProcessedOriginal) {
          link.setAttribute("download", selectedFile.Name)
        } else if (fileType === ImportResultFileType.ValidationReport) {
          link.setAttribute(
            "download",
            `validation_report_${selectedFile.ImportId}.csv`
          )
        }
        link.click()
        document.body.removeChild(link)
      })
    } catch (err: any) { // eslint-disable-line
      if (err.code && !this.isUnmounted) {
        this.setState({
          errorExist: true,
          errorType: ErrorType.Warning,
          errorMessage: `${err.code}: ${err.message}`
        })
      } else if (!this.isUnmounted) {
        this.setState({
          errorExist: true,
          errorType: ErrorType.Warning,
          errorMessage: err.Message
        })
        // eslint-disable-next-line no-console
        console.error(err)
      }
    }
  }

  private getPresignedUrl = async (
    selectedFile: ICustomerReportedFile,
    fileType: ImportResultFileType
  ) => {
    const { engagementId } = this.props
    const response = await API.graphql(
      graphqlOperation(generateDownloadS3PresignedUrlQuery, {
        engagementId,
        importJobId: selectedFile.ImportId,
        resultFileType: fileType
      })
    )
    const queryResponse = response as {
      data: IDownloadPresignedUrlQueryResponse
    }
    return queryResponse.data.generateDownloadS3PresignedUrl.PresignedUrl
  }

  private openUploadFileDialog = () => {
    this.setState({
      uploadVisible: true
    })
  }

  private dismissUploadFileDialog = () => {
    if (!this.isUploadInProgress) {
      this.setState({
        uploadVisible: false
      })
    }
  }

  private getFileFormatMessage = (fileFormat: string) => {
    type FileFormat =
      | "getFileFormatMeSimpleText"
      | "getFileFormatMeCollectorExportText"
      | "getFileFormatAdsCollectorExportText"
      | "getFileFormatRvToolsText"
      | "getFileFormatErrorText"
    try {
      const fileFormatName: FileFormat = `getFileFormat${fileFormat}Text` as FileFormat
      return this.formatMsg(messages[fileFormatName])
    } catch (e) {
      return this.formatMsg(messages.getFileFormatErrorText)
    }
  }

  private getFileValidationMessage = (fileValidation: string | null) => {
    if (!fileValidation) {
      return ""
    }
    type FileValidationTypes =
      | "getFileValidationValidText"
      | "getFileValidationValidWithWarningsText"
      | "getFileValidationInvalidText"
      | "getFileValidationErrorText"
    try {
      const fileValidationType: FileValidationTypes = `getFileValidation${fileValidation}Text` as FileValidationTypes
      return this.formatMsg(messages[fileValidationType])
    } catch (e) {
      return this.formatMsg(messages.getFileValidationErrorText)
    }
  }

  private getImportStatusMessage = (importStatus: string | null) => {
    if (!importStatus) {
      return ""
    }
    type ImportStatus =
      | "getImportStatusPendingText"
      | "getImportStatusInProgressText"
      | "getImportStatusValidationFailedText"
      | "getImportStatusImportFailedText"
      | "getImportStatusImportSucceededText"
    try {
      const fileImportStatus: ImportStatus = `getImportStatus${importStatus}Text` as ImportStatus
      return this.formatMsg(messages[fileImportStatus])
    } catch (e) {
      return this.formatMsg(messages.getImportStatusErrorText)
    }
  }

  private getLatestUploadBackoff = (
    prevLatestUploadDate: Date | undefined,
    waitTime = 300,
    maxWaitTime = 60000
  ) => {
    setTimeout(async () => {
      const getImportJobsResponse = await this.getImportJobs()
      const { listLatestImportJobsByFileName } = getImportJobsResponse
      if (waitTime > maxWaitTime) {
        this.refreshFileList()
      } else if (
        listLatestImportJobsByFileName &&
        listLatestImportJobsByFileName.ImportJobs
      ) {
        const importJobList = listLatestImportJobsByFileName.ImportJobs
        let newLatestUploadDate: Date | undefined
        importJobList.forEach((obj: IImportJob) => {
          const uploadDate = new Date(obj.CreatedOn as string)
          newLatestUploadDate =
            !newLatestUploadDate || newLatestUploadDate < uploadDate
              ? uploadDate
              : newLatestUploadDate
        })
        if (
          !newLatestUploadDate ||
          prevLatestUploadDate?.getTime() === newLatestUploadDate?.getTime()
        ) {
          this.getLatestUploadBackoff(prevLatestUploadDate, waitTime * 2)
        } else {
          this.refreshFileList()
        }
      } else {
        this.getLatestUploadBackoff(prevLatestUploadDate, waitTime * 2)
      }
    }, waitTime)
  }

  private isFailedImport = (file?: ICustomerReportedFile) => {
    return (
      file !== undefined &&
      file?.FileValidation === "" &&
      file?.ImportStatus === "Failed"
    )
  }

  private getTableHeader = () => {
    const {
      intl: { formatMessage }
    } = this.props

    const {
      loading,
      engagement: {
        getEngagement: { Name }
      },
      selectedFile,
      downloadingReport,
      downloadingProcessedOriginal
    } = this.state

    return (
      <Header
        actions={
          <SpaceBetween direction="horizontal" size="m">
            <Button
              iconName="refresh"
              onClick={this.refreshFileList}
              disabled={loading}
            />
            <Box float="left">
              <Button
                data-testid="uploadFileButton"
                variant="primary"
                iconName="upload"
                onClick={this.openUploadFileDialog}
              >
                {formatMessage(messages.uploadButtonText)}
              </Button>
            </Box>
            {getIsAutoImporterUser() && (
              <Box float="left">
                <Button
                  id="downloadReportButton"
                  variant="normal"
                  iconName="download"
                  loading={downloadingReport}
                  disabled={
                    !selectedFile ||
                    !selectedFile.ValidationReportAvailable ||
                    downloadingReport
                  }
                  target="_blank"
                  onClick={this.onDownloadValidationReport}
                >
                  {formatMessage(messages.downloadReportText)}
                </Button>
              </Box>
            )}
            {getIsAutoImporterUser() && (
              <Box float="left">
                <Button
                  id="downloadOriginalButton"
                  variant="normal"
                  iconName="download"
                  loading={downloadingProcessedOriginal}
                  disabled={
                    !selectedFile ||
                    !selectedFile.ProcessedOriginalAvailable ||
                    downloadingProcessedOriginal
                  }
                  target="_blank"
                  onClick={this.onDownloadProcessedOriginalFile}
                >
                  {formatMessage(messages.downloadOriginalText)}
                </Button>
              </Box>
            )}
          </SpaceBetween>
        }
      >
        <Box variant="span" fontSize="heading-m">
          {`${formatMessage(messages.title)}:`}
        </Box>
        &nbsp;
        <Box
          variant="span"
          fontSize="heading-m"
          color="text-status-info"
          data-testid="tableHeader"
        >
          {Name || null}
        </Box>
      </Header>
    )
  }

  updateState(
    currentData: IEngagementQueryResponse & ITempCredentialsQueryResponse
  ) {
    const { engagementId } = this.props
    if (!this.isUnmounted && engagementId === currentData.getEngagement.Id) {
      this.setState({
        engagement: currentData,
        credentials: currentData
      })
    }
  }

  render() {
    const {
      intl: { formatMessage },
      engagementId
    } = this.props

    const {
      loading,
      customerReportedFiles,
      errorExist,
      errorMessage,
      errorType,
      engagement: {
        getEngagement: { Name }
      },
      uploadVisible,
      selectedFile
    } = this.state

    return (
      <div>
        <div>
          <Alert
            id="serverError"
            header={
              errorType === ErrorType.Warning
                ? this.formatMsg(common.errorHeader)
                : this.formatMsg(messages.errorInfoHeader)
            }
            type={errorType}
            dismissible
            visible={errorExist}
            onDismiss={() => this.setState({ errorExist: false })}
            dismissAriaLabel="Close alert"
          >
            {errorMessage}
          </Alert>
        </div>
        <TableWrapper
          data-testid="s3ObjectList"
          header={this.getTableHeader()}
          loading={loading}
          loadingText={formatMessage(common.loading)}
          empty={
            <Box data-testid="customerReportedFilesEmpty">
              {formatMessage(messages.noFilesFound)}
            </Box>
          }
          items={customerReportedFiles}
          columnDefinitions={this.getColumnDefinition()}
          // use default sorting behavior
          useCollectionOptions={{ sorting: {} }}
          selectionProps={
            getIsAutoImporterUser()
              ? {
                  selectionType: "single",
                  selectedItems: selectedFile ? [selectedFile] : [],
                  onSelectionChange: ({ detail }) =>
                    this.onSelectedFileChange(detail)
                }
              : undefined
          }
        />
        <Modal
          visible={uploadVisible}
          onDismiss={this.dismissUploadFileDialog}
          header={
            <div>
              <FontAwesomeIcon size="lg" icon={faUpload} />
              &nbsp;
              <Box variant="span" fontSize="heading-m">
                {`${formatMessage(messages.modalDialogTitleText)}:`}
              </Box>
              &nbsp;
              <Box variant="span" fontSize="heading-m" color="text-status-info">
                {Name || null}
              </Box>
            </div>
          }
        >
          {" "}
          <VisibilitySensor>
            {({ isVisible }) => (
              <CustomerReportedFilesUploadSingleFile
                engagementId={engagementId}
                onFilesUploaded={() => {
                  this.isUploadInProgress = false
                  const { lastUploadDate } = this.state
                  this.getLatestUploadBackoff(lastUploadDate)
                }}
                onUploadStarted={() => {
                  this.isUploadInProgress = true
                }}
                isModalVisible={isVisible}
              />
            )}
          </VisibilitySensor>
        </Modal>
      </div>
    )
  }
}

export default injectIntl(withRouter(CustomerReportedFilesList))
