import { defineMessages } from "react-intl"

const messages = defineMessages({
  title: {
    id: "customerReportedFileList.title",
    defaultMessage: "Self-reported files"
  },
  noFilesFound: {
    id: "customerReportedFileList.noFilesFound",
    defaultMessage: "No files found"
  },
  headerFileName: {
    id: "customerReportedFileList.headerFileName",
    defaultMessage: "Name"
  },
  headerLastModified: {
    id: "customerReportedFileList.headerLastModified",
    defaultMessage: "Last modified"
  },
  headerFileFormat: {
    id: "customerReportedFileList.headerFileFormat",
    defaultMessage: "File format"
  },
  headerFileValidation: {
    id: "customerReportedFileList.headerFileValidation",
    defaultMessage: "File validation"
  },
  headerImportStatus: {
    id: "customerReportedFileList.headerImportStatus",
    defaultMessage: "Import status"
  },
  uploadButtonText: {
    id: "customerReportedFileList.uploadButtonText",
    defaultMessage: "Upload"
  },
  downloadReportText: {
    id: "customerReportedFileList.downloadReportText",
    defaultMessage: "Validation report"
  },
  downloadOriginalText: {
    id: "customerReportedFileList.downloadOriginalText",
    defaultMessage: "Download"
  },
  modalDialogTitleText: {
    id: "customerReportedFilesList.modalDialogTitleText",
    defaultMessage: "Upload self-reported files"
  },
  getEngagementErrorText: {
    id: "customerReportedFilesList.getEngagementErrorText",
    defaultMessage: "Unable to retrieve engagement"
  },
  getImportJobsErrorText: {
    id: "customerReportedFilesList.getImportJobsErrorText",
    defaultMessage: "Unable to retrieve import information for engagement"
  },
  getFileFormatErrorText: {
    id: "customerReportedFileList.getFileFormatErrorText",
    defaultMessage: "Unknown file format"
  },
  getFileFormatMeSimpleText: {
    id: "customerReportedFileList.getFileFormatMeSimpleText",
    defaultMessage: "Data Import Template"
  },
  getFileFormatMeCollectorExportText: {
    id: "customerReportedFileList.getFileFormatMeCollectorExportText",
    defaultMessage: "Inventory and Utilization Export"
  },
  getFileFormatAdsCollectorExportText: {
    id: "customerReportedFileList.getFileFormatAdsCollectorExportText",
    defaultMessage: "Application Discovery Service Export"
  },
  getFileFormatRvToolsText: {
    id: "customerReportedFileList.getFileFormatRvToolsText",
    defaultMessage: "RV Tools"
  },
  getImportStatusErrorText: {
    id: "customerReportedFileList.getImportStatusErrorText",
    defaultMessage: "Unknown import status"
  },
  getImportStatusPendingText: {
    id: "customerReportedFileList.getImportStatusPendingText",
    defaultMessage: "Pending"
  },
  getImportStatusInProgressText: {
    id: "customerReportedFileList.getImportStatusInProgressText",
    defaultMessage: "In progress"
  },
  getImportStatusValidationFailedText: {
    id: "customerReportedFileList.getImportStatusValidationFailedText",
    defaultMessage: "File validation failed"
  },
  getImportStatusImportFailedText: {
    id: "customerReportedFileList.getImportStatusImportFailedText",
    defaultMessage: "Failed"
  },
  getImportStatusImportSucceededText: {
    id: "customerReportedFileList.getImportStatusImportSucceededText",
    defaultMessage: "Succeeded"
  },
  getFileValidationErrorText: {
    id: "customerReportedFileList.getFileValidationErrorText",
    defaultMessage: "Unknown file validation status"
  },
  getFileValidationValidText: {
    id: "customerReportedFileList.getFileValidationValidText",
    defaultMessage: "Valid"
  },
  getFileValidationValidWithWarningsText: {
    id: "customerReportedFileList.getFileValidationValidWithWarningsText",
    defaultMessage: "Valid with warnings"
  },
  getFileValidationInvalidText: {
    id: "customerReportedFileList.getFileValidationInvalidText",
    defaultMessage: "Invalid"
  },
  errorInfoHeader: {
    id: "customerReportedFileList.errorInfoHeader",
    defaultMessage: "Expect longer processing time"
  },
  errorInfoText: {
    id: "customerReportedFileList.errorInfoText",
    defaultMessage:
      "Please anticipate longer processing time for your last uploaded file. If you need further support, please reach out to your Migration Evaluator program manager."
  }
})

export default messages
